import {
  getGridStringOperators,
  GRID_STRING_COL_DEF,
  GRID_SINGLE_SELECT_COL_DEF,
  GRID_DATE_COL_DEF,
  GRID_NUMERIC_COL_DEF,
  getGridSingleSelectOperators,
  getGridDateOperators,
  getGridNumericOperators,
  jaJP,
} from '@mui/x-data-grid-pro';
import { generateLicense, LicenseInfo } from '@mui/x-license-pro';
import { TFunction } from 'i18next';

import CustomGridFilterInputDate from '~/components/custom-grid-filter-input-date';

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: '1',
    licensingModel: 'subscription',
    scope: 'premium',
  })
);

export const setUpDataGrid = (t: TFunction) => {
  GRID_STRING_COL_DEF.filterOperators = getGridStringOperators().filter(({ value }) => value === 'equals');
  GRID_SINGLE_SELECT_COL_DEF.filterOperators = getGridSingleSelectOperators().filter(({ value }) => value === 'is');
  GRID_DATE_COL_DEF.filterOperators = getGridDateOperators()
    .filter(({ value }) => value !== 'isEmpty' && value !== 'isNotEmpty' && value !== 'not')
    .map((value) => {
      value.InputComponent = CustomGridFilterInputDate;
      return value;
    });
  GRID_NUMERIC_COL_DEF.filterOperators = getGridNumericOperators().filter(
    ({ value }) => value !== 'isEmpty' && value !== 'isNotEmpty' && value !== 'not' && value !== 'isAnyOf'
  );

  jaJP.components.MuiDataGrid.defaultProps.localeText.filterPanelRemoveAll = t<string>('remove_all');
};
