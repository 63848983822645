import { useMemo, useRef } from 'react';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  TypePolicy,
  from,
  defaultDataIdFromObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useTranslation } from 'react-i18next';

import { GraphqlServerEnum } from '~/enums/graphql-server.enum';
import { env } from '~/env';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const customFieldPolicy: Required<TypePolicy>['fields'][string] = {
  keyArgs: (args, context) => {
    return (context.field?.alias?.value || '') + JSON.stringify(context.variables);
  },
};

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    if (responseObject.__typename && responseObject.uuid) {
      return `${responseObject.__typename}:${responseObject.uuid}`;
    }
    return defaultDataIdFromObject(responseObject);
  },
});

export const WithApolloClient: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { i18n } = useTranslation();
  const i18nRef = useRef(i18n.language);
  i18nRef.current = i18n.language;
  const user = useFirebaseUser();
  const client = useMemo(() => {
    const asyncAuthLink = setContext(async (request, prevContext) => {
      const token = user && (await user.getIdToken());
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'Accept-Language': i18nRef.current,
        },
      };
    });

    const bankLink = asyncAuthLink.concat(
      createHttpLink({
        uri: env.REACT_APP_API_ADMIN_SERVER,
      })
    );

    const subqueryLink = createHttpLink({
      uri: env.REACT_APP_API_SUBQUERY_SERVER,
    });

    const linkSwitch = split(
      ({ getContext }) => getContext().apiName === GraphqlServerEnum.Subquery,
      subqueryLink,
      bankLink
    );

    const errorLink = onError(({ graphQLErrors }) => {
      graphQLErrors?.forEach((err) => {
        const messageI18n = (err.extensions?.localizedMessages as Record<string, string>)?.[i18nRef.current];
        if (messageI18n) {
          err.message = messageI18n;
        }
      });
    });

    return new ApolloClient({
      link: from([errorLink, linkSwitch]),
      cache,
    });
  }, [user]);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
