import { useEffect } from 'react';

import { RouteObject } from 'react-router';
import { useRoutes, useLocation } from 'react-router-dom';

import { AppRouteEnum } from '~/enums/route.enum';
import { WithApolloClient } from '~/graphql/client';
import { WithAccount } from '~/hooks/with-account';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';
import AboutPage from '~/pages/about';
import DashboardPage from '~/pages/dashboard';
import ErrorPage from '~/pages/error';
import SignInPage from '~/pages/sign-in';
import TokenListPage from '~/pages/token-list';

export const routerConfig: RouteObject[] = [
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: AppRouteEnum.AUTH,
    element: (
      <WithFirebaseUser>
        <SignInPage />
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.DASHBOARD,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <DashboardPage />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.TOKEN_LIST,
    element: (
      <WithFirebaseUser>
        <WithApolloClient>
          <WithAccount>
            <TokenListPage />
          </WithAccount>
        </WithApolloClient>
      </WithFirebaseUser>
    ),
  },
  {
    path: '*',
    element: <ErrorPage code={404} />,
  },
];

const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routerConfig);
};

export default AppRouter;
