import React, { useCallback, useState } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useLogout } from '~/hooks/use-logout';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 400,
  },
  walletAddress: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
  anchorEl: MenuProps['anchorEl'];
}

const UserPopover: React.FC<Props> = (props) => {
  const { open, onClose, anchorEl } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const firebaseUser = useFirebaseUser();
  const { t } = useTranslation();
  const logout = useLogout();

  const onLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        autoFocus={false}
      >
        <MenuItem>
          <ListItemAvatar>
            <Avatar variant="rounded" src={firebaseUser?.photoURL || ''} />
          </ListItemAvatar>
          <ListItemText primary={firebaseUser?.displayName} secondary={firebaseUser?.email} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserPopover;
