import React, { useCallback, useState } from 'react';

import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from 'tss-react/mui';

import HeaderBar from './header-bar';
import SideBar from './side-bar';

import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from '~/constants/layout';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {},
  rightSide: {
    position: 'relative',
    marginLeft: DRAWER_WIDTH,
    transition: 'all 250ms ease 0s',
    [theme.breakpoints.down('lg')]: {
      marginLeft: DRAWER_WIDTH_SM,
    },
  },
  rightSideTemporarySideBar: {
    marginLeft: 0,
  },
  contentWraper: {
    minHeight: 'calc(100vh - 64px)',
  },
  content: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

const UserLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children } = props;
  const { classes, cx, theme } = useStyles(undefined, { props: { classes: props.classes } });
  const [openDrawer, setOpenDrawer] = useState(false);
  const isTemporarySideBar = useMediaQuery(theme.breakpoints.down('md'));

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((open) => !open);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <div className={classes.root} data-testid="layout">
      <HeaderBar isTemporarySideBar={isTemporarySideBar} onToggleDrawer={onToggleDrawer} />
      <SideBar isTemporarySideBar={isTemporarySideBar} openDrawer={openDrawer} onCloseDrawer={onCloseDrawer} />
      <div className={cx(classes.rightSide, isTemporarySideBar && classes.rightSideTemporarySideBar)}>
        <div className={classes.contentWraper}>
          <Toolbar />
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
