import React, { useCallback, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { StyledComponentProps } from '../../../types/material-ui';

export type NavigatorItemConfig =
  | {
      path?: string;
      label: string;
      icon?: JSX.Element;
      children?: NavigatorItemConfig[];
    }
  | 'divider';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    borderRadius: 4,
    height: 48,
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 40,
    '& img': {
      width: 23,
      height: 23,
    },
  },
  divider: {
    margin: 1,
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  item: NavigatorItemConfig;
}

const NavigatorItem: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { item } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (item === 'divider') {
      return;
    }
    if (item.path) {
      navigate(item.path);
    } else if (item.children) {
      setOpen((o) => !o);
    }
  }, [item, navigate]);

  if (item === 'divider') {
    return <Divider className={classes.divider} />;
  }

  return (
    <>
      <ListItem button selected={location.pathname === item.path} className={classes.listItem} onClick={handleClick}>
        {item.icon && <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>}
        <ListItemText primary={item.label} />
        {item.children && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {item.children.map((i, index) => (
              <NavigatorItem item={i} key={index} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavigatorItem;
