import React, { useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '../dialog-close-button';

import { ListTokensDocument, useAddTokenMutation } from '~/graphql/admin/types';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({}));

interface Props extends DialogProps {
  onClose: () => void;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const AddTokenDialog: React.FC<Props> = (props) => {
  const { open, onClose, ...others } = props;

  return (
    <Dialog open={open} onClose={onClose} {...others} maxWidth="sm" fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<Props> = (props) => {
  const { onClose } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const [addToken] = useAddTokenMutation({
    refetchQueries: [ListTokensDocument],
  });

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().max(128).required(),
        symbol: yup.string().max(128).required(),
        tokenAddress: yup.string().ethAddress().required(),
        description: yup.string().max(128).required(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  interface FormValues extends yup.InferType<typeof schema> {}
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      symbol: '',
      tokenAddress: '',
      description: '',
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await addToken({
          variables: {
            input: {
              name: data.name,
              symbol: data.symbol,
              tokenAddress: data.tokenAddress,
              description: data.description,
            },
          },
        });
        onClose();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [addToken, onClose, enqueueSnackbar]
  );

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('add_token')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              label={t('name')}
              variant="outlined"
              margin="normal"
              sx={{ marginTop: 0 }}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="symbol"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              label={t('symbol')}
              variant="outlined"
              margin="normal"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="tokenAddress"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              label={t('token_address')}
              variant="outlined"
              margin="normal"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              label={t('description')}
              variant="outlined"
              margin="normal"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddTokenDialog;
