import React, { useMemo } from 'react';

import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from 'tss-react/mui';

import { StyledComponentProps } from '../../types/material-ui';

import NavigatorItem, { NavigatorItemConfig } from './navigator-item';

const useStyles = makeStyles()((theme) => ({}));

interface Props extends StyledComponentProps<typeof useStyles> {
  config: NavigatorItemConfig[];
}

const NavigatorMenu: React.FC<Props> = (props) => {
  const { config } = props;

  const renderNavigatorItems = useMemo(
    () => config.map((item, index) => <NavigatorItem item={item} key={index} />),
    [config]
  );

  return (
    <List>
      <Toolbar />
      {renderNavigatorItems}
    </List>
  );
};

export default NavigatorMenu;
