import React, { useMemo } from 'react';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import NavigatorMenu from '~/components/navigator-menu';
import { NavigatorItemConfig } from '~/components/navigator-menu/navigator-item';
import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from '~/constants/layout';
import { AppRouteEnum } from '~/enums/route.enum';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    padding: theme.spacing(2),
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH,
    },
  },
  logo: {
    '& img': {
      width: 130,
      [theme.breakpoints.up('sm')]: {
        width: 170,
      },
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}

const SideBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, openDrawer, onCloseDrawer } = props;
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const config = useMemo<NavigatorItemConfig[]>(
    () => [
      {
        path: AppRouteEnum.DASHBOARD,
        label: t('dashboard'),
        icon: <DashboardOutlinedIcon />,
      },
      {
        path: AppRouteEnum.TOKEN_LIST,
        label: t('token_list'),
        icon: <ArticleOutlinedIcon />,
      },
      // {
      //   path: AppRouteEnum.PROFILE_SETTING,
      //   icon: <AccountCircleOutlinedIcon />,
      //   label: t('profile_setting'),
      // },
      // {
      //   path: AppRouteEnum.USER_MANAGEMENT,
      //   label: t('admin_user'),
      //   icon: <ManageAccountsOutlinedIcon />,
      // },
    ],
    [t]
  );

  return (
    <Drawer
      variant={isTemporarySideBar ? 'temporary' : 'persistent'}
      open={isTemporarySideBar ? openDrawer : true}
      onClose={onCloseDrawer}
      classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      data-testid="side-bar"
    >
      <NavigatorMenu config={config} />
    </Drawer>
  );
};

export default SideBar;
