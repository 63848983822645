import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'admin' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  JSONObject: any;
};

export type AddAdminUserInput = {
  /** Email, this field is a string type */
  email: Scalars['String'];
  /** User roles, this field is an array of enum type */
  roles: Array<AdminUserRole>;
};

export type AddTokenInput = {
  /** Description of token, this field is a string type with max length of 128. */
  description?: InputMaybe<Scalars['String']>;
  /** Name of token, this field is a string type with max length of 128. */
  name: Scalars['String'];
  /** Symbol of token, this field is a string type with max length of 128. */
  symbol: Scalars['String'];
  /** Token address, this field is a string type with max length of 128. */
  tokenAddress: Scalars['String'];
};

export type AdminUser = {
  /** Created at */
  createdAt: Scalars['Date'];
  /** Email */
  email: Scalars['String'];
  /** Firebase uid */
  firebaseUid: Scalars['String'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Profile language */
  profileLanguage: LanguageEnum;
  /** Profile timezone */
  profileTimezone?: Maybe<Scalars['String']>;
  /** User roles */
  roles: Array<AdminUserRole>;
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Admin user uuid */
  uuid: Scalars['String'];
};

export enum AdminUserOperationEnum {
  AddAdminUser = 'ADD_ADMIN_USER',
  AddToken = 'ADD_TOKEN',
  DeleteAdminUser = 'DELETE_ADMIN_USER',
  SwitchOwner = 'SWITCH_OWNER',
  UpdateAdminUser = 'UPDATE_ADMIN_USER',
  UpdateToken = 'UPDATE_TOKEN',
}

export type AdminUserOperationLog = {
  /** Admin user */
  adminUser?: Maybe<AdminUser>;
  /** Admin user uuid */
  adminUserUuid: Scalars['String'];
  /** Created at */
  createdAt: Scalars['Date'];
  /** Operation */
  operation: AdminUserOperationEnum;
  /** Operation detail */
  operationDetail?: Maybe<Scalars['JSONObject']>;
  /** Type */
  type: AdminUserOperationTypeEnum;
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Log uuid */
  uuid: Scalars['String'];
};

export enum AdminUserOperationTypeEnum {
  AdminSetting = 'ADMIN_SETTING',
}

export enum AdminUserRole {
  Administrator = 'ADMINISTRATOR',
  Manager = 'MANAGER',
  Operator = 'OPERATOR',
  Owner = 'OWNER',
  SeniorManager = 'SENIOR_MANAGER',
}

export type DateFilter = {
  equal?: InputMaybe<Scalars['Date']>;
  greaterThan?: InputMaybe<Scalars['Date']>;
  greaterThanOrEqual?: InputMaybe<Scalars['Date']>;
  lessThan?: InputMaybe<Scalars['Date']>;
  lessThanOrEqual?: InputMaybe<Scalars['Date']>;
};

export enum LanguageEnum {
  En = 'EN',
  Ja = 'JA',
}

export type ListAdminUserOperationLogsFilter = {
  /** Account uuid, this field is an optional string type */
  accountUuid?: InputMaybe<Scalars['String']>;
  /** Admin user uuid, this field is an optional string type */
  adminUserUuid?: InputMaybe<Scalars['String']>;
  /** Created at, this field is an optional object type */
  createdAt?: InputMaybe<DateFilter>;
  /** Operation, this field is an optional enum type */
  operation?: InputMaybe<AdminUserOperationEnum>;
  /** Type, this field is an optional enum type */
  type?: InputMaybe<AdminUserOperationTypeEnum>;
  /** Uuid, this field is an optional string type */
  uuid?: InputMaybe<Scalars['String']>;
};

export type ListAdminUserOperationLogsResponse = {
  /** Items */
  items: Array<AdminUserOperationLog>;
  /** Pagination */
  pagination: Pagination;
};

export enum ListAdminUserOperationLogsSortBy {
  CreatedAt = 'CREATED_AT',
}

export type ListAdminUsersFilter = {
  /** Email, this field is an optional string type */
  email?: InputMaybe<Scalars['String']>;
  /** User role, this field is an optional enum type */
  role?: InputMaybe<AdminUserRole>;
  /** Admin user uuid, this field is an optional string type */
  uuid?: InputMaybe<Scalars['String']>;
};

export type ListAdminUsersResponse = {
  /** Items */
  items: Array<AdminUser>;
  /** Pagination */
  pagination: Pagination;
};

export enum ListAdminUsersSortBy {
  CreatedAt = 'CREATED_AT',
}

export type ListTokensFilter = {
  /** Name, this field is an optional string type */
  name?: InputMaybe<Scalars['String']>;
  /** Symbol, this field is an optional string type */
  symbol?: InputMaybe<Scalars['String']>;
  /** Token address, this field is an optional string type */
  tokenAddress?: InputMaybe<Scalars['String']>;
};

export type ListTokensResponse = {
  /** Items */
  items: Array<Token>;
  /** Pagination */
  pagination: Pagination;
};

export enum ListTokensSortBy {
  CreatedAt = 'CREATED_AT',
}

export type Mutation = {
  addAdminUser: AdminUser;
  addToken: Token;
  deleteAdminUser: Scalars['Boolean'];
  deleteToken: Scalars['Boolean'];
  switchOwner: Scalars['Boolean'];
  updateAdminUser: AdminUser;
  updateProfile: AdminUser;
  updateToken: Token;
};

export type MutationAddAdminUserArgs = {
  input: AddAdminUserInput;
};

export type MutationAddTokenArgs = {
  input: AddTokenInput;
};

export type MutationDeleteAdminUserArgs = {
  adminUserUid: Scalars['String'];
};

export type MutationDeleteTokenArgs = {
  tokenUuid: Scalars['String'];
};

export type MutationSwitchOwnerArgs = {
  adminUserUid: Scalars['String'];
};

export type MutationUpdateAdminUserArgs = {
  input: UpdateAdminUserInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateTokenArgs = {
  input: UpdateTokenInput;
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Pagination = {
  /** Current page */
  currentPage: Scalars['Int'];
  /** Items per page */
  itemsPerPage: Scalars['Int'];
  /** Total items */
  totalItems: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type Query = {
  getAdminUser: AdminUser;
  getMe: AdminUser;
  getToken: Token;
  listAdminUserOperationLogs: ListAdminUserOperationLogsResponse;
  listAdminUsers: ListAdminUsersResponse;
  listTokens: ListTokensResponse;
};

export type QueryGetAdminUserArgs = {
  adminUserUuid: Scalars['String'];
};

export type QueryGetTokenArgs = {
  tokenUuid: Scalars['String'];
};

export type QueryListAdminUserOperationLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListAdminUserOperationLogsSortBy>;
  where?: InputMaybe<ListAdminUserOperationLogsFilter>;
};

export type QueryListAdminUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListAdminUsersSortBy>;
  where?: InputMaybe<ListAdminUsersFilter>;
};

export type QueryListTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListTokensSortBy>;
  where?: InputMaybe<ListTokensFilter>;
};

export type Token = {
  /** Created at */
  createdAt: Scalars['Date'];
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Name */
  name: Scalars['String'];
  /** Symbol */
  symbol: Scalars['String'];
  /** Token address */
  tokenAddress: Scalars['String'];
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Admin user uuid */
  uuid: Scalars['String'];
};

export type UpdateAdminUserInput = {
  /** Admin user uuid */
  adminUserUuid: Scalars['String'];
  /** User roles this field is an array of enum type */
  roles: Array<AdminUserRole>;
};

export type UpdateProfileInput = {
  /** Language, this field is an optional enum type */
  profileLanguage?: InputMaybe<LanguageEnum>;
  /** Timezone, this field is an optional string type */
  profileTimezone?: InputMaybe<Scalars['String']>;
};

export type UpdateTokenInput = {
  /** Description of token, this field is a string type with max length of 128. */
  description?: InputMaybe<Scalars['String']>;
  /** Name of token, this field is a string type with max length of 128. */
  name?: InputMaybe<Scalars['String']>;
  /** Symbol of token, this field is a string type with max length of 128. */
  symbol?: InputMaybe<Scalars['String']>;
  /** Token uuid */
  uuid: Scalars['String'];
};

export type AddTokenMutationVariables = Exact<{
  input: AddTokenInput;
}>;

export type AddTokenMutation = {
  addToken: {
    uuid: string;
    name: string;
    symbol: string;
    tokenAddress: string;
    description?: string | null;
    createdAt: string;
    updatedAt?: string | null;
  };
};

export type DeleteTokenMutationVariables = Exact<{
  tokenUuid: Scalars['String'];
}>;

export type DeleteTokenMutation = { deleteToken: boolean };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  updateProfile: {
    uuid: string;
    firebaseUid: string;
    name?: string | null;
    email: string;
    roles: Array<AdminUserRole>;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    createdAt: string;
    updatedAt?: string | null;
  };
};

export type UpdateTokenMutationVariables = Exact<{
  input: UpdateTokenInput;
}>;

export type UpdateTokenMutation = {
  updateToken: {
    uuid: string;
    name: string;
    symbol: string;
    tokenAddress: string;
    description?: string | null;
    createdAt: string;
    updatedAt?: string | null;
  };
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  getMe: {
    uuid: string;
    firebaseUid: string;
    name?: string | null;
    email: string;
    roles: Array<AdminUserRole>;
    profileLanguage: LanguageEnum;
    profileTimezone?: string | null;
    createdAt: string;
    updatedAt?: string | null;
  };
};

export type ListTokensQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListTokensSortBy>;
  orderBy?: InputMaybe<OrderBy>;
  where?: InputMaybe<ListTokensFilter>;
}>;

export type ListTokensQuery = {
  listTokens: {
    items: Array<{
      uuid: string;
      name: string;
      symbol: string;
      tokenAddress: string;
      description?: string | null;
      createdAt: string;
      updatedAt?: string | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export const AddTokenDocument = gql`
  mutation addToken($input: AddTokenInput!) {
    addToken(input: $input) {
      uuid
      name
      symbol
      tokenAddress
      description
      createdAt
      updatedAt
    }
  }
`;
export type AddTokenMutationFn = Apollo.MutationFunction<AddTokenMutation, AddTokenMutationVariables>;

/**
 * __useAddTokenMutation__
 *
 * To run a mutation, you first call `useAddTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTokenMutation, { data, loading, error }] = useAddTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTokenMutation, AddTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTokenMutation, AddTokenMutationVariables>(AddTokenDocument, options);
}
export type AddTokenMutationHookResult = ReturnType<typeof useAddTokenMutation>;
export type AddTokenMutationResult = Apollo.MutationResult<AddTokenMutation>;
export type AddTokenMutationOptions = Apollo.BaseMutationOptions<AddTokenMutation, AddTokenMutationVariables>;
export const DeleteTokenDocument = gql`
  mutation deleteToken($tokenUuid: String!) {
    deleteToken(tokenUuid: $tokenUuid)
  }
`;
export type DeleteTokenMutationFn = Apollo.MutationFunction<DeleteTokenMutation, DeleteTokenMutationVariables>;

/**
 * __useDeleteTokenMutation__
 *
 * To run a mutation, you first call `useDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokenMutation, { data, loading, error }] = useDeleteTokenMutation({
 *   variables: {
 *      tokenUuid: // value for 'tokenUuid'
 *   },
 * });
 */
export function useDeleteTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTokenMutation, DeleteTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTokenMutation, DeleteTokenMutationVariables>(DeleteTokenDocument, options);
}
export type DeleteTokenMutationHookResult = ReturnType<typeof useDeleteTokenMutation>;
export type DeleteTokenMutationResult = Apollo.MutationResult<DeleteTokenMutation>;
export type DeleteTokenMutationOptions = Apollo.BaseMutationOptions<DeleteTokenMutation, DeleteTokenMutationVariables>;
export const UpdateProfileDocument = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      uuid
      firebaseUid
      name
      email
      roles
      profileLanguage
      profileTimezone
      createdAt
      updatedAt
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const UpdateTokenDocument = gql`
  mutation updateToken($input: UpdateTokenInput!) {
    updateToken(input: $input) {
      uuid
      name
      symbol
      tokenAddress
      description
      createdAt
      updatedAt
    }
  }
`;
export type UpdateTokenMutationFn = Apollo.MutationFunction<UpdateTokenMutation, UpdateTokenMutationVariables>;

/**
 * __useUpdateTokenMutation__
 *
 * To run a mutation, you first call `useUpdateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenMutation, { data, loading, error }] = useUpdateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTokenMutation, UpdateTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTokenMutation, UpdateTokenMutationVariables>(UpdateTokenDocument, options);
}
export type UpdateTokenMutationHookResult = ReturnType<typeof useUpdateTokenMutation>;
export type UpdateTokenMutationResult = Apollo.MutationResult<UpdateTokenMutation>;
export type UpdateTokenMutationOptions = Apollo.BaseMutationOptions<UpdateTokenMutation, UpdateTokenMutationVariables>;
export const GetMeDocument = gql`
  query getMe {
    getMe {
      uuid
      firebaseUid
      name
      email
      roles
      profileLanguage
      profileTimezone
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const ListTokensDocument = gql`
  query listTokens($page: Int, $limit: Int, $sortBy: ListTokensSortBy, $orderBy: OrderBy, $where: ListTokensFilter) {
    listTokens(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy, where: $where) {
      items {
        uuid
        name
        symbol
        tokenAddress
        description
        createdAt
        updatedAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListTokensQuery__
 *
 * To run a query within a React component, call `useListTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTokensQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListTokensQuery(baseOptions?: Apollo.QueryHookOptions<ListTokensQuery, ListTokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTokensQuery, ListTokensQueryVariables>(ListTokensDocument, options);
}
export function useListTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTokensQuery, ListTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTokensQuery, ListTokensQueryVariables>(ListTokensDocument, options);
}
export type ListTokensQueryHookResult = ReturnType<typeof useListTokensQuery>;
export type ListTokensLazyQueryHookResult = ReturnType<typeof useListTokensLazyQuery>;
export type ListTokensQueryResult = Apollo.QueryResult<ListTokensQuery, ListTokensQueryVariables>;
