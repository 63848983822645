import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import FirebaseAuthLocalized from '~/components/firebase-auth-localized';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {
    width: '100%',
    maxWidth: 560,
  },
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    textAlign: 'center',
  },
  language: {
    width: 155,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
  emailConfirmationWaring: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  btnLogout: {
    margin: theme.spacing(0.5, 0, 2, 0),
    textTransform: 'uppercase',
  },
  logo: {
    width: 279,
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    marginBottom: theme.spacing(4),
  },
}));

const SignInPage: React.FC = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();
  const firebaseUser = useFirebaseUser();

  useEffect(() => {
    document.title = t('sign_in');
  }, [t]);

  const handleLogout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  const onLanguageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    },
    [i18n]
  );
  const config: firebaseui.auth.Config = useMemo(
    () => ({
      signInSuccessUrl: '#',
    }),
    []
  );

  return (
    <Container className={classes.root}>
      <div className={classes.wrapper}>
        <Paper className={classes.paper} elevation={0}>
          <img className={classes.logo} src="/images/gu-logo-text.svg" alt="" draggable={false} />
          <div className={classes.content}>
            <div>
              {firebaseUser && !firebaseUser.emailVerified ? (
                <Box marginTop={2}>
                  <Typography variant="body1" className={classes.emailConfirmationWaring}>
                    {t('email_confirmation_warning')}
                  </Typography>
                  <Button className={classes.btnLogout} variant="outlined" onClick={handleLogout}>
                    {t('logout')}
                  </Button>
                </Box>
              ) : (
                <FirebaseAuthLocalized language={i18n.language} config={config} />
              )}
            </div>
          </div>
        </Paper>
        <div className={classes.language}>
          <TextField
            name="language"
            onChange={onLanguageChange}
            value={i18n.language}
            select
            color="primary"
            variant="outlined"
            fullWidth
            hiddenLabel
            size="small"
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </Container>
  );
};

export default SignInPage;

export const languageOptions = [
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'en',
    label: 'English',
  },
];
