import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ja from './locales/ja.json';

const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: ja,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('language') || 'ja',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

declare module 'react-i18next' {
  interface CustomTypeOptions {
    resources: typeof resources['en'];
  }
}
