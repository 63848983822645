import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import firebase from 'firebase/app';

export const useLogout = () => {
  const client = useApolloClient();
  const logout = useCallback(async () => {
    await client.clearStore();
    await firebase.auth().signOut();
  }, [client]);

  return logout;
};
