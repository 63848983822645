import { TFunction } from 'i18next';

import { ErrorPageType } from '~/pages/error';

export const getErrorPageMessageI18n = (t: TFunction, type?: ErrorPageType) => {
  switch (type) {
    case ErrorPageType.notFound:
      return t('error_page.not_found');
    default:
      return '-';
  }
};
