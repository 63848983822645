import { GridFilterInputDateProps } from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function CustomGridFilterInputDate(props: GridFilterInputDateProps) {
  const { t } = useTranslation();
  return (
    <DatePicker
      sx={{ width: 'calc(100% - 8px)' }}
      format={t('date_format')}
      onChange={(e) => {
        props.applyValue({ ...props.item, value: e?.startOf('day') });
      }}
      value={props.item.value ? moment(props.item.value) : null}
      slotProps={{
        actionBar: {
          actions: ['clear', 'accept'],
        },
        textField: {
          label: props.apiRef.current.getLocaleText('filterPanelInputLabel'),
          fullWidth: true,
          variant: 'standard',
          margin: 'none',
        },
      }}
    />
  );
}

export default CustomGridFilterInputDate;
