import React, { createContext, useContext, useEffect } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useLogout } from './use-logout';

import AuthLayout from '~/components/auth-layout';
import ScreenLoading from '~/components/screen-loading';
import { AdminUser, AdminUserRole, LanguageEnum, useGetMeQuery, useUpdateProfileMutation } from '~/graphql/admin/types';

const AccountContext = createContext<{ account: AdminUser }>({} as any);

export const useAccount = () => useContext(AccountContext);

const useStyles = makeStyles()((theme) => ({
  bottomWrapper: {
    paddingTop: theme.spacing(4),
  },
}));

export const WithAccount: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();
  const { data: getMeData, loading: loadingGetMe } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const logout = useLogout();

  if (loadingGetMe) {
    return <ScreenLoading />;
  }

  if (!getMeData?.getMe) {
    return (
      <AuthLayout headerTitle="Forbidden">
        <Typography variant="body1" color="textPrimary" component="p">
          {t('unauthenticated_error_page.description')}
        </Typography>
        <Button color="primary" variant="outlined" onClick={logout} sx={{ marginTop: 2 }}>
          {t('logout')}
        </Button>
      </AuthLayout>
    );
  }

  return (
    <AccountContext.Provider value={{ account: getMeData.getMe }}>
      <Wrap>{props.children}</Wrap>
    </AccountContext.Provider>
  );
};

const Wrap: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { account } = useAccount();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateProfile, { loading: loadingUpdateProfile }] = useUpdateProfileMutation();
  useEffect(() => {
    if (i18n.language !== account.profileLanguage.toLowerCase()) {
      i18n.changeLanguage(account.profileLanguage.toLowerCase());
    }
  }, [account.profileLanguage, i18n]);

  useEffect(() => {
    const onChage = async () => {
      if (i18n.language !== account.profileLanguage.toLowerCase()) {
        updateProfile({
          variables: {
            input: {
              profileLanguage: i18n.language === 'ja' ? LanguageEnum.Ja : LanguageEnum.En,
            },
          },
        }).catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      }
    };

    i18n.on('languageChanged', onChage);
    return () => {
      i18n.off('languageChanged', onChage);
    };
  }, [account.profileLanguage, enqueueSnackbar, i18n, updateProfile]);

  useEffect(() => {
    moment.tz.setDefault(account.profileTimezone || undefined);
  }, [account.profileTimezone]);

  return <>{props.children}</>;
};
