import React, { useCallback, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import LanguageMenu from './language-menu';
import UserMenu from './user-menu';

import { HEADER_HEIGHT } from '~/constants/layout';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    height: HEADER_HEIGHT,
  },
  toolbarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbarContentRight: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
  avatar: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  avatarImg: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
  },
  languageText: {
    marginRight: 10,
  },
  logo: {
    color: '#3E5B72',
    height: 30,
    display: 'block',
    pointerEvents: 'none',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  onToggleDrawer: () => void;
}

const HeaderBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, onToggleDrawer } = props;
  const { t, i18n } = useTranslation();
  const { classes, theme } = useStyles(undefined, { props: { classes: props.classes } });
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setUserMenuAnchorEl(event.currentTarget);
    },
    [setUserMenuAnchorEl]
  );
  const onCloseUserMenu = useCallback(() => {
    setUserMenuAnchorEl(null);
  }, [setUserMenuAnchorEl]);

  const onOpenLanguageMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setLanguageMenuAnchorEl(event.currentTarget);
    },
    [setLanguageMenuAnchorEl]
  );
  const onCloseLanguageMenu = useCallback(() => {
    setLanguageMenuAnchorEl(null);
  }, [setLanguageMenuAnchorEl]);

  return (
    <>
      <UserMenu open={Boolean(userMenuAnchorEl)} onClose={onCloseUserMenu} anchorEl={userMenuAnchorEl} />
      <AppBar className={classes.appBar} color="inherit">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarContent}>
            {isTemporarySideBar && (
              <IconButton onClick={onToggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <RouterLink to="/">
              <img className={classes.logo} src="/images/gu-logo-text.svg" alt="" />
            </RouterLink>
            <div className={classes.toolbarContentRight}>
              {isSmallScreen ? (
                <IconButton color="inherit" onClick={onOpenLanguageMenu}>
                  <LanguageIcon />
                </IconButton>
              ) : (
                <>
                  <Button
                    onClick={onOpenLanguageMenu}
                    color="inherit"
                    startIcon={<LanguageIcon />}
                    endIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="body1">{i18n.language}</Typography>
                  </Button>
                </>
              )}
              <LanguageMenu
                open={Boolean(languageMenuAnchorEl)}
                onClose={onCloseLanguageMenu}
                anchorEl={languageMenuAnchorEl}
              />

              <div className={classes.avatar} onClick={onOpenUserMenu}>
                <AccountCircleIcon color="action" />
                <ArrowDropDownIcon color="action" />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderBar;
