import { useCallback, useEffect, useMemo, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  gridClasses,
  GridColDef,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddDataGridToolbar from '~/components/add-data-grid-toolbar';
import AddTokenDialog from '~/components/add-token-dialog';
import UserLayout from '~/components/app-layout/user-layout';
// import UpdateTokenDialog from '~/components/update-token-address-status-dialog';
import UpdateTokenDialog from '~/components/update-token-dialog';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from '~/constants/view';
import { AppRouteEnum } from '~/enums/route.enum';
import {
  ListTokensDocument,
  ListTokensSortBy,
  OrderBy,
  useDeleteTokenMutation,
  useListTokensQuery,
} from '~/graphql/admin/types';
import { useConfirmationDialog } from '~/hooks/use-confirmation-dialog';

const useStyles = makeStyles()(() => ({}));

const MAP_FIELD_TO_SORT_BY: Record<string, ListTokensSortBy> = {
  createAt: ListTokensSortBy.CreatedAt,
};

const MAP_SORT_TO_ORDER: Record<string, OrderBy> = {
  asc: OrderBy.Asc,
  desc: OrderBy.Desc,
};

const TokenListPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: {} });
  const [openAddTokenDialog, setOpenAddTokenDialog] = useState(false);
  const [openUpdateTokenDialog, setOpenUpdateTokenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Row>();
  const { confirmDialog } = useConfirmationDialog();
  const [deleteToken] = useDeleteTokenMutation({
    refetchQueries: [ListTokensDocument],
  });

  const { enqueueSnackbar } = useSnackbar();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'createdAt', sort: 'desc' }]);
  const { data: listTokensData, loading: loadingListTokens } = useListTokensQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy: sortModel[0]?.sort ? MAP_SORT_TO_ORDER[sortModel[0].sort] : undefined,
      sortBy: sortModel[0]?.field ? MAP_FIELD_TO_SORT_BY[sortModel[0].field] : undefined,
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      where:
        filterModel?.items.reduceRight((acc, item) => {
          return { ...acc, [item.field]: item.value };
        }, {}) || {},
    },
  });

  useEffect(() => {
    document.title = t('token_list');
  }, [t]);

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
    });
  }, [enqueueSnackbar, t]);

  const onPaginationModelChange = useCallback<NonNullable<DataGridProProps['onPaginationModelChange']>>((model) => {
    setPaginationModel(model);
  }, []);

  const onFilterModelChange = useCallback<NonNullable<DataGridProProps['onFilterModelChange']>>(
    (model) => {
      setFilterModel(model);
    },
    [setFilterModel]
  );

  const onSortModelChange = useCallback<NonNullable<DataGridProProps['onSortModelChange']>>(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );
  const rows = useMemo(
    () =>
      (listTokensData?.listTokens.items || [])
        .filter((e): e is Exclude<typeof e, null> => e !== null)
        .map((item) => ({
          id: item.uuid,
          uuid: item.uuid,
          name: item.name,
          symbol: item.symbol,
          tokenAddress: item.tokenAddress,
          description: item.description,
          createdAt: item.createdAt,
        })),
    [listTokensData?.listTokens?.items]
  );

  type Row = typeof rows[number];

  const onOpenUpdateTokenDialog = useCallback(
    (row: Row) => () => {
      setSelectedRow(row);
      setOpenUpdateTokenDialog(true);
    },
    []
  );

  const onOpenDeleteTokenDialog = useCallback(
    (row: Row) => () => {
      confirmDialog({
        async onYes() {
          await deleteToken({
            variables: {
              tokenUuid: row.id,
            },
          });
        },
      });
    },
    [confirmDialog, deleteToken]
  );

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'actions',
        type: 'actions',
        headerName: t('action'),
        width: 100,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={onOpenUpdateTokenDialog(params.row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={onOpenDeleteTokenDialog(params.row)}
              color="inherit"
            />,
          ];
        },
      },
      {
        field: 'name',
        headerName: t('name'),
        sortable: false,
        filterable: false,
        minWidth: 200,
      },
      {
        field: 'symbol',
        headerName: t('symbol'),
        sortable: false,
        filterable: false,
        minWidth: 200,
      },
      {
        field: 'tokenAddress',
        headerName: t('token_address'),
        sortable: false,
        minWidth: 200,
        renderCell(params) {
          return (
            <>
              <div className={gridClasses.cellContent}>{params.formattedValue}</div>
              <CopyToClipboard text={params.formattedValue} onCopy={onCopyAddress}>
                <IconButton size="small">
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </CopyToClipboard>
            </>
          );
        },
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('description'),
        sortable: false,
        filterable: false,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: t('created_at'),
        sortingOrder: ['asc', 'desc'],
        filterable: false,
        minWidth: 200,
        flex: 1,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_time_format')) : '-'),
      },
    ],
    [onCopyAddress, onOpenDeleteTokenDialog, onOpenUpdateTokenDialog, t]
  );

  const onCloseUpdateTokenDialog = useCallback(() => {
    setOpenUpdateTokenDialog(false);
    setSelectedRow(undefined);
  }, []);

  const onOpenAddTokenDialog = useCallback(() => {
    setOpenAddTokenDialog(true);
  }, []);

  const onCloseAddTokenDialog = useCallback(() => {
    setOpenAddTokenDialog(false);
  }, []);

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          component={RouterLink}
          to={AppRouteEnum.DASHBOARD}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>
        <Typography color="text.primary">{t('token_list')}</Typography>
      </Breadcrumbs>

      {selectedRow && (
        <UpdateTokenDialog token={selectedRow} open={openUpdateTokenDialog} onClose={onCloseUpdateTokenDialog} />
      )}

      <AddTokenDialog open={openAddTokenDialog} onClose={onCloseAddTokenDialog} />

      <div style={{ width: '100%', height: 500 }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          loading={loadingListTokens}
          // autoHeight
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          rowCount={listTokensData?.listTokens?.pagination.totalItems || 0}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          pagination
          components={{
            Toolbar: AddDataGridToolbar,
          }}
          componentsProps={{
            toolbar: { onClickAdd: onOpenAddTokenDialog, label: t('add') },
          }}
          disableVirtualization
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </div>
    </UserLayout>
  );
};

export default TokenListPage;
