import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { GridToolbar, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({}));

interface AddDataGridToolbarProps {
  onClickAdd: () => void;
  label?: string;
}

const AddDataGridToolbar = (props: AddDataGridToolbarProps) => {
  const { onClickAdd, label, ...others } = props;
  const { t } = useTranslation();

  return (
    <>
      <GridToolbarContainer>
        <Button color="primary" onClick={onClickAdd} startIcon={<AddIcon />}>
          {label || t('add')}
        </Button>
      </GridToolbarContainer>
      <GridToolbar {...others} />
    </>
  );
};

export default AddDataGridToolbar;
